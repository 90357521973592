import styles from './HeroSubText.module.scss';
import { HeroSubTextType } from "./HeroSubText.types";

const HeroSubText = () => {
  return (
    <>
      <h3 className={styles.hero_sub_text_title}>業界最先端技術×伝統施術</h3>
      <p className={styles.hero_sub_text}>オーダーメイドのハイブリッド施術で、<br className="pc-none" />あなたの理想の身体を、<br className='sp-none' />自在なスタッフが<br className="pc-none" />全力でサポートいたします。</p>
    </>
  )
}

export default HeroSubText;