import styles from './HeroText.module.scss';
import { HeroTextType } from "./HeroText.types";

const HeroText = (props: HeroTextType) => {
  const {text} = props;
  return (
    <h2 className={styles.hero_text}>{text}</h2>
  )
}

export default HeroText;