"use client";
import HomeFlashNewsItems from "../../../../app/_home/components/HomeFlashNewsItems/HomeFlashNewsItems";
import useFetchLatestContents from "../../../hooks/useFetchLatestContents.hooks";
import { PostsLatestBoxTopType } from "./PostsLatestBoxTop.types";

const PostsLatestBoxTop = (props: PostsLatestBoxTopType) => {
  const {microCMSAuth, microCMSQueries, postsPerPage} = props;

  const {isLoading, response, hasError, errorMessage} = useFetchLatestContents(microCMSAuth, microCMSQueries, postsPerPage);

  if (isLoading) {
    return (
      <></>
    );
  };

  if (hasError) {
    return (
      <p>{errorMessage}</p>
    );
  }

  return (
    <HomeFlashNewsItems
      listItem={response.contents}
      postsPerPage={postsPerPage}
    />
  )
}

export default PostsLatestBoxTop;