import { useEffect, useState } from "react";
import { MicroCMSAuthType, MicroCMSLatestNewsQueryType } from "../types/MicroCMSConfig.types";
import { getMicroCMSConnection } from "../utils/getMicroCMSConnection";

/**
 * このフックはトップページの最新のお知らせ表示データ取得フックスです。app/_settings/_configs/Endpoints.tsのEndpointsの投稿データを引数のpostsPerPage分ずつ取得して日付の新しい順に並び替えてpostsPerPage分返却します。
 * @param microCMSAuth 
 * @param microCMSQueries 
 * @param postsPerPage 
 * @returns 
 */
const useFetchLatestContents = (
  microCMSAuth: MicroCMSAuthType,
  microCMSQueries: MicroCMSLatestNewsQueryType,
  postsPerPage: number,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [hasError, setHasError] = useState(false);
  const errorMessage = "投稿リストが取得できません。";

  useEffect(() => {
    const client = getMicroCMSConnection(microCMSAuth.serviceDomain, microCMSAuth.apiKey);

    const getContentsList = async () => {
      let allContents: any[] = [];

      try {
        for (const key of Object.keys(microCMSQueries.endpoints)) {
          const endpoint = microCMSQueries.endpoints[key];
          const endpointResponse = await client.get({
            endpoint: endpoint.id,
            queries: {
              limit: postsPerPage,
              orders: '-publishedAt'
            },
          });

          // 各エンドポイントの内容をすべてallContentsに追加
          allContents = [
            ...allContents, 
            ...endpointResponse.contents.map((content: any) => ({
              ...content,
              endpoint: key,
              label: endpoint.label,
            }))
          ];
        }

        // publishedAtで新しい順に並び替え
        allContents.sort((a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime());

        // 上位5件を抽出
        const topContents = allContents.slice(0, postsPerPage);

        // 最終的なレスポンスとして設定
        setResponse({ contents: topContents });
      } catch (e) {
        console.error(e);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    };

    getContentsList();
  }, [microCMSAuth, microCMSQueries, postsPerPage]);
  return { isLoading, response, hasError, errorMessage };
};

export default useFetchLatestContents;
