'use client';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { MicroCMSGetAuth } from '../../../../app/_settings/_configs/MicroCMSConfig';
import { HomeLatestPostsQuery } from '../../../../app/_settings/_configs/MicroCMSQueries';
import ResponsiveImage from '../../elements/images/ResponsiveImage/ResponsiveImage';
import HeroSubText from '../../elements/texts/HeroSubText/HeroSubText';
import HeroText from '../../elements/texts/HeroText/HeroText';
import styles from './Hero.module.scss';
import { HeroType } from './Hero.type';

import PostsLatestBoxTop from '@/components/objects/PostsLatestBoxTop/PostsLatestBoxTop';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// Swiperの必要なモジュールを追加
SwiperCore.use([Navigation, Pagination, Autoplay]);

const Hero = (props: HeroType) => {
  const { heroImage, heroText, heroSubText } = props;

  return (
    <section className={`hero-section ${styles.hero}`}>
      <div className={styles.hero__image}>
        <div className="hero__image__swiper">
          <Swiper
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            spaceBetween={50}
            slidesPerView={3}
            modules={[Navigation, Pagination,EffectFade]}
            // navigation={true}
            pagination={true}
            effect="fade"
          >
            <SwiperSlide>
              <ResponsiveImage
                srcPC="/images/fv_01.png"
                srcSP="/images/fv_01_sp.png"
                altText="Healthy body,fulfilling life."
              />
            </SwiperSlide>
            <SwiperSlide>
              <ResponsiveImage
                srcPC="/images/fv_02.png"
                srcSP="/images/fv_02_sp.png"
                altText="Healthy body,fulfilling life."
              />
            </SwiperSlide>
            <SwiperSlide>
            <ResponsiveImage
                srcPC="/images/fv_03.png"
                srcSP="/images/fv_03_sp.png"
                altText="Healthy body,fulfilling life."
              />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className={styles.hero__catch}>
          <HeroText text={<>Healthy body,<br />fulfilling life.</>} />
          <HeroSubText />
        </div>
        <PostsLatestBoxTop
          microCMSAuth={MicroCMSGetAuth}
          microCMSQueries={HomeLatestPostsQuery}
          postsPerPage={3}
        />
      </div>
      {/* Add more SwiperSlide components for additional slides */}
    </section>
  );
};

export default Hero;