const getDiffDays = (date: string) => {

  // 取得した日付のミリ秒を取得
  const dateObj = Date.parse(date);

  // 今日の日付のミリ秒を取得
  const today = Date.now();

  const diffMilliseconds = today - dateObj;

   // ミリ秒を日数に変換
   const diffDays = Math.floor(diffMilliseconds / (1000 * 60 * 60 * 24));

  return diffDays;
}

export default getDiffDays;