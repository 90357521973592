import { EndpointsType } from "@/types/Endpoints.types";
import { Endpoints, TopLatestNewsEndpoints } from "./Endpoints";
import { Meta } from "./SiteInfo";

// ダミーデータ。
// 全部のエンドポイントが出揃ったら消して、HomeLatestPostsQueryのendpointsのコメントアウトを入れ替えて
const Endpoints_demo: EndpointsType = {
  "news_common": {
    id: "news_common",
    name: "自在な整骨院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '全院',
    archivePath: 'news',
    description: Meta.siteName + "のお知らせ"
  },

  // 防府院
  "news_hofu": {
    id: "news_hofu",
    name: "防府院からのお知らせ",
    categories: {},
    postsPerPage: 10,
    label: '防府',
    archivePath: 'shop/hofu/news',
    description: Meta.siteName + " | 防府院からのお知らせ"    
  },
}

export const HomeLatestPostsQuery = {
  endpoints: TopLatestNewsEndpoints,
  postsPerPage: 5,
}

export const PostsArchiveQuery = {
  endpointId: Endpoints["news_common"].id,
  postsPerPage: Endpoints["news_common"].postsPerPage
}

export const CommonNewsArchiveQuery = {
  endpointId: Endpoints["news_common"].id,
  postsPerPage: Endpoints["news_common"].postsPerPage
}

// 防府院
export const NewsHofuArchiveQuery = {
  endpointId: Endpoints["news_hofu"].id,
  postsPerPage: Endpoints["news_hofu"].postsPerPage
};
export const BlogHofuArchiveQuery = {
  endpointId: Endpoints["blog_hofu"].id,
  postsPerPage: Endpoints["blog_hofu"].postsPerPage
};
export const VoiceHofuArchiveQuery = {
  endpointId: Endpoints["voice_hofu"].id,
  postsPerPage: Endpoints["voice_hofu"].postsPerPage
};

// 延岡院
export const NewsNobeokaArchiveQuery = {
  endpointId: Endpoints["news_nobeoka"].id,
  postsPerPage: Endpoints["news_nobeoka"].postsPerPage
};
export const BlogNobeokaArchiveQuery = {
  endpointId: Endpoints["blog_nobeoka"].id,
  postsPerPage: Endpoints["blog_nobeoka"].postsPerPage
};
export const VoiceNobeokaArchiveQuery = {
  endpointId: Endpoints["voice_nobeoka"].id,
  postsPerPage: Endpoints["voice_nobeoka"].postsPerPage
};

// 下関院
export const NewsShimonosekiArchiveQuery = {
  endpointId: Endpoints["news_shimonoseki"].id,
  postsPerPage: Endpoints["news_shimonoseki"].postsPerPage
};
export const BlogShimonosekiArchiveQuery = {
  endpointId: Endpoints["blog_shimonoseki"].id,
  postsPerPage: Endpoints["blog_shimonoseki"].postsPerPage
};
export const VoiceShimonosekiArchiveQuery = {
  endpointId: Endpoints["voice_shimonoseki"].id,
  postsPerPage: Endpoints["voice_shimonoseki"].postsPerPage
};

// 篠栗院
export const NewsSasaguriArchiveQuery = {
  endpointId: Endpoints["news_sasaguri"].id,
  postsPerPage: Endpoints["news_sasaguri"].postsPerPage
};
export const BlogSasaguriArchiveQuery = {
  endpointId: Endpoints["blog_sasaguri"].id,
  postsPerPage: Endpoints["blog_sasaguri"].postsPerPage
};
export const VoiceSasaguriArchiveQuery = {
  endpointId: Endpoints["voice_sasaguri"].id,
  postsPerPage: Endpoints["voice_sasaguri"].postsPerPage
};

// 直方院
export const NewsNoogataArchiveQuery = {
  endpointId: Endpoints["news_noogata"].id,
  postsPerPage: Endpoints["news_noogata"].postsPerPage
};
export const BlogNoogataArchiveQuery = {
  endpointId: Endpoints["blog_noogata"].id,
  postsPerPage: Endpoints["blog_noogata"].postsPerPage
};
export const VoiceNoogataArchiveQuery = {
  endpointId: Endpoints["voice_noogata"].id,
  postsPerPage: Endpoints["voice_noogata"].postsPerPage
};

// 店舗ページ用のposts_per_page数を定義
export const PostsPerPageForShopPage = {
  newsPostsNumber: 3,
  blogPostsNumber: 3,
  voicePostsNumber: 8
}