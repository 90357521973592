// GET API以外は絶対に.env以外に置かない
// .envを使える(node環境でSSRするとき)ようになったら.envに移設してこのファイルは消すこと
import { MicroCMSAuthType } from "../../../src/types/MicroCMSConfig.types"

export const MicroCMSGetAuth: MicroCMSAuthType = {
  // 本番
  serviceDomain: "zizaina",
  apiKey: "gVMvmstWqRWbYB6SaYmZ44Y9xyM9qxiITyoE"

  // 検証
  // serviceDomain: "microocmstest",
  // apiKey: "MiJkePa8RzF8xfPU3GFSImlO8m71L2CD7ug8"
}